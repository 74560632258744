// src/presets/nora/metergroup/index.ts
var metergroup_default = {
  root: {
    borderRadius: "{content.border.radius}",
    gap: "1rem"
  },
  meters: {
    size: "0.5rem"
  },
  label: {
    gap: "0.5rem"
  },
  labelMarker: {
    size: "0.5rem"
  },
  labelIcon: {
    size: "1rem"
  },
  labelList: {
    verticalGap: "0.5rem",
    horizontalGap: "1rem"
  },
  colorScheme: {
    light: {
      meters: {
        background: "{surface.300}"
      }
    },
    dark: {
      meters: {
        background: "{surface.600}"
      }
    }
  }
};
export { metergroup_default as default };
