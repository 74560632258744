import { definePreset } from '@primeng/themes';
import Nora from '@primeng/themes/nora';

export function toteimTheme(): any {
  return definePreset(Nora, {
    primitive: {
      borderRadius: {
        none: '0',
        xs: '4px',
        sm: '6px',
        md: '8px',
        lg: '10px',
        xl: '12px'
      },
      blue: {
        50: '#B6E2F6',
        100: '#A4DBF4',
        200: '#7FCCF0',
        300: '#5BBEEC',
        400: '#36AFE7',
        500: '#1895CF',
        600: '#1584B7',
        700: '#116992',
        800: '#116992',
        900: '#093549',
        950: '#041A25'
      },
      green: {
        50: '#DBE6B3',
        100: '#D4E0A3',
        200: '#CDDB94',
        300: '#C6D685',
        400: '#BFD175',
        500: '#B5CB61',
        600: '#B0C757',
        700: '#A9C247',
        800: '#9FB83D',
        900: '#92A838',
        950: '#859933'
      }
    },
    semantic: {
      transitionDuration: '0s',
      focusRing: {
        width: '2px',
        style: 'solid',
        color: '{primary.color}',
        offset: '2px',
        shadow: 'none'
      },
      iconSize: '1rem',
      anchorGutter: '0',
      primary: {
        50: '{blue.50}',
        100: '{blue.100}',
        200: '{blue.200}',
        300: '{blue.300}',
        400: '{blue.400}',
        500: '{blue.500}',
        600: '{blue.600}',
        700: '{blue.700}',
        800: '{blue.800}',
        900: '{blue.900}',
        950: '{blue.950}'
      },
      formField: {
        paddingX: '0.75rem',
        paddingY: '0.5rem',
        borderRadius: '{border.radius.xs}',
        focusRing: {
          width: '2px',
          style: 'solid',
          color: '{primary.color}',
          offset: '-1px',
          shadow: 'none'
        },
        transitionDuration: '{transition.duration}'
      },
      list: {
        padding: '0.125rem 0',
        gap: '0',
        header: {
          padding: '0.5rem 0.75rem 0.375rem 0.75rem'
        },
        option: {
          padding: '0.5rem 0.75rem',
          borderRadius: '0'
        },
        optionGroup: {
          padding: '0.5rem 0.75rem',
          fontWeight: '700'
        }
      },
      content: {
        borderRadius: '{border.radius.xs}'
      },
      mask: {
        transitionDuration: '0.15s'
      },
      navigation: {
        list: {
          padding: '0.125rem 0',
          gap: '0'
        },
        item: {
          padding: '0.5rem 0.75rem',
          borderRadius: '0',
          gap: '0.5rem'
        },
        submenuLabel: {
          padding: '0.5rem 0.75rem',
          fontWeight: '700'
        },
        submenuIcon: {
          size: '0.875rem'
        }
      },
      overlay: {
        select: {
          borderRadius: '{border.radius.xs}',
          shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
        },
        popover: {
          borderRadius: '{border.radius.xs}',
          padding: '0.75rem',
          shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
        },
        modal: {
          borderRadius: '{border.radius.xs}',
          padding: '1.25rem',
          shadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)'
        },
        navigation: {
          shadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
        }
      },
      colorScheme: {
        light: {
          surface: {
            0: '#ffffff',
            50: '{slate.50}',
            100: '{slate.100}',
            200: '{slate.200}',
            300: '{slate.300}',
            400: '{slate.400}',
            500: '{slate.500}',
            600: '{slate.600}',
            700: '{slate.700}',
            800: '{slate.800}',
            900: '{slate.900}',
            950: '{slate.950}'
          },
          primary: {
            color: '{primary.500}',
            contrastColor: '#ffffff',
            hoverColor: '{primary.700}',
            activeColor: '{primary.800}'
          },
          highlight: {
            background: '{primary.600}',
            focusBackground: '{primary.700}',
            color: '#ffffff',
            focusColor: '#ffffff'
          },
          mask: {
            background: 'rgba(0,0,0,0.4)',
            color: '{surface.200}'
          },
          formField: {
            background: '{surface.0}',
            disabledBackground: '{surface.300}',
            filledBackground: '{surface.100}',
            filledFocusBackground: '{surface.100}',
            borderColor: '{surface.500}',
            hoverBorderColor: '{surface.500}',
            focusBorderColor: '{surface.500}',
            invalidBorderColor: '{red.500}',
            color: '{surface.900}',
            disabledColor: '{surface.600}',
            placeholderColor: '{surface.600}',
            floatLabelColor: '{surface.600}',
            floatLabelFocusColor: '{surface.600}',
            floatLabelInvalidColor: '{red.500}',
            iconColor: '{surface.900}',
            shadow: 'none'
          },
          text: {
            color: '{surface.900}',
            hoverColor: '{surface.950}',
            mutedColor: '{surface.600}',
            hoverMutedColor: '{surface.700}'
          },
          content: {
            background: '{surface.0}',
            hoverBackground: '{surface.200}',
            borderColor: '{surface.400}',
            color: '{text.color}',
            hoverColor: '{text.hover.color}'
          },
          overlay: {
            select: {
              background: '{surface.0}',
              borderColor: 'transparent',
              color: '{text.color}'
            },
            popover: {
              background: '{surface.0}',
              borderColor: 'transparent',
              color: '{text.color}'
            },
            modal: {
              background: '{surface.0}',
              borderColor: 'transparent',
              color: '{text.color}'
            }
          },
          list: {
            option: {
              focusBackground: '{surface.200}',
              selectedBackground: '{highlight.background}',
              selectedFocusBackground: '{highlight.focus.background}',
              color: '{text.color}',
              focusColor: '{text.hover.color}',
              selectedColor: '{highlight.color}',
              selectedFocusColor: '{highlight.focus.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{text.hover.muted.color}'
              }
            },
            optionGroup: {
              background: 'transparent',
              color: '{text.color}'
            }
          },
          navigation: {
            item: {
              focusBackground: '{primary.color}',
              activeBackground: '{surface.200}',
              color: '{text.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.hover.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{primary.contrast.color}',
                activeColor: '{text.hover.muted.color}'
              }
            },
            submenuLabel: {
              background: 'transparent',
              color: '{text.color}'
            },
            submenuIcon: {
              color: '{text.muted.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.hover.muted.color}'
            }
          }
        },
        dark: {
          surface: {
            0: '#ffffff',
            50: '{zinc.50}',
            100: '{zinc.100}',
            200: '{zinc.200}',
            300: '{zinc.300}',
            400: '{zinc.400}',
            500: '{zinc.500}',
            600: '{zinc.600}',
            700: '{zinc.700}',
            800: '{zinc.800}',
            900: '{zinc.900}',
            950: '{zinc.950}'
          },
          primary: {
            color: '{primary.500}',
            contrastColor: '{surface.950}',
            hoverColor: '{primary.400}',
            activeColor: '{primary.300}'
          },
          highlight: {
            background: '{primary.500}',
            focusBackground: '{primary.400}',
            color: '{surface.950}',
            focusColor: '{surface.950}'
          },
          mask: {
            background: 'rgba(0,0,0,0.6)',
            color: '{surface.200}'
          },
          formField: {
            background: '{surface.950}',
            disabledBackground: '{surface.700}',
            filledBackground: '{surface.800}',
            filledFocusBackground: '{surface.800}',
            borderColor: '{surface.500}',
            hoverBorderColor: '{surface.500}',
            focusBorderColor: '{surface.500}',
            invalidBorderColor: '{red.400}',
            color: '{surface.0}',
            disabledColor: '{surface.400}',
            placeholderColor: '{surface.400}',
            floatLabelColor: '{surface.400}',
            floatLabelFocusColor: '{surface.400}',
            floatLabelInvalidColor: '{red.400}',
            iconColor: '{surface.0}',
            shadow: 'none'
          },
          text: {
            color: '{surface.0}',
            hoverColor: '{surface.0}',
            mutedColor: '{surface.400}',
            hoverMutedColor: '{surface.300}'
          },
          content: {
            background: '{surface.900}',
            hoverBackground: '{surface.700}',
            borderColor: '{surface.500}',
            color: '{text.color}',
            hoverColor: '{text.hover.color}'
          },
          overlay: {
            select: {
              background: '{surface.900}',
              borderColor: '{surface.700}',
              color: '{text.color}'
            },
            popover: {
              background: '{surface.900}',
              borderColor: '{surface.700}',
              color: '{text.color}'
            },
            modal: {
              background: '{surface.900}',
              borderColor: '{surface.700}',
              color: '{text.color}'
            }
          },
          list: {
            option: {
              focusBackground: '{surface.700}',
              selectedBackground: '{highlight.background}',
              selectedFocusBackground: '{highlight.focus.background}',
              color: '{text.color}',
              focusColor: '{text.hover.color}',
              selectedColor: '{highlight.color}',
              selectedFocusColor: '{highlight.focus.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{text.hover.muted.color}'
              }
            },
            optionGroup: {
              background: 'transparent',
              color: '{text.color}'
            }
          },
          navigation: {
            item: {
              focusBackground: '{primary.color}',
              activeBackground: '{surface.700}',
              color: '{text.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.color}',
              icon: {
                color: '{text.muted.color}',
                focusColor: '{primary.contrast.color}',
                activeColor: '{text.hover.muted.color}'
              }
            },
            submenuLabel: {
              background: 'transparent',
              color: '{text.color}'
            },
            submenuIcon: {
              color: '{text.muted.color}',
              focusColor: '{primary.contrast.color}',
              activeColor: '{text.hover.muted.color}'
            }
          }
        }
      }
    },
    components: {
      button: {
        border: {
          radius: '{border.radius.lg}'
        }
      },
      card: {
        border: {
          radius: '{border.radius.lg}'
        }
      }
    }
  });
}
