// src/presets/nora/skeleton/index.ts
var skeleton_default = {
  root: {
    borderRadius: "{content.border.radius}"
  },
  colorScheme: {
    light: {
      root: {
        background: "{surface.300}",
        animationBackground: "rgba(255,255,255,0.4)"
      }
    },
    dark: {
      root: {
        background: "rgba(255, 255, 255, 0.1)",
        animationBackground: "rgba(255, 255, 255, 0.04)"
      }
    }
  }
};
export { skeleton_default as default };
