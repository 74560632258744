// src/presets/nora/toolbar/index.ts
var toolbar_default = {
  root: {
    background: "{content.background}",
    borderColor: "{content.border.color}",
    borderRadius: "{content.border.radius}",
    color: "{content.color}",
    gap: "0.5rem",
    padding: "0.75rem"
  }
};
export { toolbar_default as default };
