// src/presets/nora/card/index.ts
var card_default = {
  root: {
    background: "{content.background}",
    borderRadius: "{border.radius.sm}",
    color: "{content.color}",
    shadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)"
  },
  body: {
    padding: "1.25rem",
    gap: "0.5rem"
  },
  caption: {
    gap: "0.5rem"
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "500"
  },
  subtitle: {
    color: "{text.muted.color}"
  }
};
export { card_default as default };
