import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { TranslateService } from "@ngx-translate/core";
import { Button } from 'primeng/button';
import { Card } from 'primeng/card';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    Button,
    Card
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  userLang: string = 'en';

  constructor(private translateService: TranslateService) {
    this.userLang = navigator.language;

    translateService.addLangs(environment.langs);
    translateService.setDefaultLang('en');

    if (this.userLang && this.userLang.length >= 2) {
      this.userLang = this.userLang.slice(0, 2)
    }

    if (localStorage.getItem('language')) {
      this.userLang = localStorage.getItem('language')!;
    }

    if (environment.langs.includes(this.userLang)) {
      translateService.use(this.userLang);
      localStorage.setItem('language', this.userLang);
    }
    else {
      this.userLang = 'fr';
      translateService.use('fr');
      localStorage.setItem('language', 'fr');
    }
  }
}
