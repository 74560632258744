import { Routes } from '@angular/router';
import { AuthGuard, GuestGuard } from '@toteimlib/angular-lib';

export const appRoutes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/dashboard/dashboard.routes').then(m => m.dashboardRoutes)
  },
  {
    path: '',
    canActivate: [GuestGuard],
    loadChildren: () => import('./components/guest/guest.routes').then(m => m.guestRoutes)
  },
  {
    path: '**',
    redirectTo: ''
  }
];
