import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {ApiService, AuthService, I18nService, ThemeService, ToastService } from '@toteimlib/angular-lib';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    TranslateModule,
    ToastModule
  ],
  providers: [ToastService, MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(private translateService: TranslateService, private i18nService: I18nService, private authService: AuthService, private apiService: ApiService, private themeService: ThemeService) {
    let userLang: string = this.i18nService.getLang();

    this.translateService.addLangs(environment.langs);
    this.translateService.setDefaultLang('fr');

    this.apiService.initialize(environment.api_url, environment.version, '', '/dashboard/home', '/login');
    this.authService.setRedirectUrl('/login');
    this.authService.setLocalStorageList([
      'api_token',
      'user',
      'property_id',
      'property'
    ]);

    if (environment.langs.includes(userLang)) {
      this.translateService.use(userLang);
    }
    else {
      this.translateService.use('fr');
    }
  }

}
