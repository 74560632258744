export const environment = {
  production: false,
  url: 'https://dev.v2.toteim.com',
  api_url: 'https://apidev.v2.toteim.com',
  langs: [
    'en',
    'fr'
  ],
  version: 'b06863ee7fb3484a09a4ef51f26b197323c93f4d'
};
