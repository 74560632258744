// src/presets/nora/orderlist/index.ts
var orderlist_default = {
  root: {
    gap: "1.125rem"
  },
  controls: {
    gap: "0.5rem"
  }
};
export { orderlist_default as default };
